<template lang="pug">
#Spiral
  #Spiral-container
    #Spiral__design
      #Spiral__start-button(@click="startSpiralAnimation") {{ startReload }}
      #Spiral__animation
        .phone.deep-shadow
        .phone.near-shadow
          #phone-inner
      #Spiral__explanation
        .header-text-container
          .hero-heading 1st algorithm
          .shape-outside
          .text-heading
            p.
              This was my first attempt at coding...
            p.
              I believe the best way to learn a new skill is to start practicing right
              away and learn as we go, so with some basic knowledge of Python, I tried to fix a problem where we needed to
              "draw" a spiral given some height and width.
            p.
              Took me a bit but was worth it, and its with the same pleasure I still code today...
            p.
              Just press START to see the animation
            p.
              Not bad for a 1st try... right?!
    #Spiral__code
      .code-container
        .mouse_3
</template>

<script>
import { computed, ref } from 'vue';
import confetti from 'canvas-confetti';

export default {
  name: 'Spiral',
  setup () {

    function showConfetti () {
      let count = 200;
      let defaults = {
        origin: {
          x: 0.3,
          y: 0.5,
        },
      };

      function fire (particleRatio, opts) {
        confetti(Object.assign({}, defaults, opts, {
          particleCount: Math.floor(count * particleRatio),
        }));
      }

      fire(0.25, {
        spread: 26,
        startVelocity: 55,
        zIndex: 200,
      });
      fire(0.2, {
        spread: 60,
        zIndex: 200,
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
        zIndex: 200,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
        zIndex: 200,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
        zIndex: 200,
      });
    }


    let hasAnimationRunOnce = ref(false);
    let theBox;
    let move = 'right';
    let x, y;
    let minX, minY;
    let maxX, maxY;
    let boxSize;
    let index;
    let pixelating = false;
    let hue = 0;


    function setPixel () {
      let newDiv = document.createElement('div');
      newDiv.className = 'SpiralSquare';
      newDiv.style.transform = `translate(${x * 38}px, ${y * 38}px)`;
      newDiv.style.backgroundColor = `hsl(${hue + index}, 80%, 80%)`;
      theBox.appendChild(newDiv);
      index++;
      updatePositions();
    }


    function updatePositions () {
      if (move === 'right') {
        x++;
        if (x === maxX - 1) {
          minX++;
          move = 'down';
        }
      } else if (move === 'down') {
        y++;
        if (y === maxY - 1) {
          maxX--;
          move = 'left';
        }
      } else if (move === 'left') {
        x--;
        if (x === minY) {
          maxY--;
          move = 'up';
        }
      } else if (move === 'up') {
        y--;
        if (y === minX) {
          minY++;
          move = 'right';
        }
      }
    }


    let createPixel = () => {
      if (index > boxSize) {
        showConfetti();
        return pixelating = false;
      }

      setPixel();
      setTimeout(createPixel, 35);
    };


    function innit () {
      theBox = document.getElementById('phone-inner');
      if (theBox) {
        index = 1;
        maxX = 10;
        maxY = 16;
        boxSize = maxX * maxY;
        hue = Math.floor(Math.random() * 360) + index;
        theBox.innerHTML = '';
        move = 'right';
        minX = 0;
        minY = 0;
        x = 0;
        y = 0;
        if (!pixelating) {
          pixelating = true;
          hasAnimationRunOnce.value = true;
          createPixel();
        }
      }
    }


    innit();


    let startReload = computed(() => {
      return hasAnimationRunOnce.value ? 'Reload' : 'Start';
    });


    function startSpiralAnimation () {
      innit();
    }


    return {
      startSpiralAnimation,
      startReload,
    };
  },
};
</script>
<style lang="stylus">
.SpiralSquare
  transition transform 5s, opacity 5s
  font-size 7px
  background-color black
  width 33px
  height 33px
  position absolute
  display flex
  align-items center
  justify-content center
  border-radius 5px
</style>

<style lang="stylus" scoped>
#Spiral
  position absolute
  z-index 100
  top 0
  right 0
  bottom 0
  left 0
  overflow hidden
  display flex
  align-items center
  justify-content center
  #Spiral-container
    position absolute
    z-index 100
    top 70px
    bottom 70px
    min-width 1100px
    max-width 1400px
    display flex
    align-items stretch
    border-radius 20px
    #Spiral__design
      position relative
      background-image radial-gradient(at left bottom, #555 0%, #313335 40%)
      background-color #313335
      flex 1
      display flex
      align-items stretch
      border-radius 20px 0 0 20px
      #Spiral__start-button
        font 600 20px 'Kalam'
        text-transform uppercase
        color white
        cursor pointer
        position absolute
        z-index 10000
        bottom 30px
        right 40px
      #Spiral__animation
        flex 1
        perspective 1000px
        position relative
        user-select none
        cursor default
        box-sizing border-box
        .phone
          position absolute
          top 50%
          left 50%
          width 420px
          height 642px
          transform translate(-50%, -50%) rotate3d(1, 1, 0, 32deg) rotateZ(-10deg)
          border-radius 8px
          &.deep-shadow
            box-shadow: -15px 60px 125px -23px rgba(50, 50, 50, 1), -6px 37px 75px -35px rgba(0, 0, 0, 1);
          &.near-shadow
            background linear-gradient(90deg, #fffbe5, white);
            box-shadow: -5px 18px 40px -12px rgba(20, 20, 20, 1);
          #phone-inner
            position absolute
            overflow hidden
            top 20px
            right 20px
            bottom 20px
            left 20px
      #Spiral__explanation
        position relative
        width 330px
        color white
        .header-text-container
          position absolute
          top 50px
          left -20px
          width 330px
          z-index 9000
          .shape-outside
            shape-outside polygon(0 0, 0 700px, 300px 800px)
            width 320px
            float left
            height 400px
            opacity .2
          .hero-heading
            text-align left
            color #e4ecff
            font 500 30px 'Cabin Sketch'
            line-height 40px
            margin 0 0 20px 0
          .hero-heading::before
            content ''
            width 42px
            height 3px
            position absolute
            top -20px
            left 0
            background-color #EF3934
          .text-heading
            color #e4ecff
            padding-right 20px
            font 500 15px / 20px 'Kalam'
            text-align left
            &::first-letter
              font-size 30px
            p
              margin-bottom 25px
    #Spiral__code
      width 420px
      background-image url('../../public/spiral/CodeEditorBg.png')
      background-repeat repeat-y
      background-size contain
      overflow-x none
      overflow-y auto
      -ms-overflow-style none /* IE and Edge */
      scrollbar-width none /* Firefox */
      border-radius 0 20px 20px 0
      &::-webkit-scrollbar
        display none
      .code-container
        width 420px
        height 1050px
        background-image url('../../public/spiral/SpiralCode.png')
        background-repeat no-repeat
        background-size contain

/* mouse 1 */
.mouse_1
  position absolute
  bottom 10px
  right 10px
  width 15px
  height 40px
  border 1px solid #697cb4
  box-sizing border-box
  border-radius 20px
  &::before
    content ''
    width 6px
    height 10px
    position absolute
    opacity 1
    top 0
    transform: translateY(28px);
    left calc(50% - 3px)
    background #a1a8c2
    border-radius 5px
    animation wheel 5s infinite

@keyframes wheel
  0%
    opacity: .3;
    transform: translateY(3px);
  25%
    opacity: 1;
    transform: translateY(15px);
  50%
    opacity: .3;
    transform: translateY(25px);
  75%
    opacity: 1;
    transform: translateY(15px);
  100%
    opacity: .3;
    transform: translateY(3px);

/* mouse 2 */
.mouse_2
  position absolute
  bottom 10px
  right 10px
  width 20px
  height 50px
  border 1px solid #697cb4
  box-sizing border-box
  border-radius 20px
  &::before
    content ''
    display block
    position absolute
    width 8px
    height 8px
    border-radius 50%
    top 6px
    left calc(50% - 4px)
    background #697cb4
  &::after
    content ''
    display block
    position absolute
    top 25px
    left calc(50% - 4px)
    border 4px solid transparent
    border-top 8px solid #697cb4
    animation mouse_scroll_arrow 2s ease-in-out infinite

@keyframes mouse_scroll_arrow
  20%, 100%
    transform: translateY(0)
  50%
    transform: translateY(12px)
  80%
    transform: translateY(-5px)

/* mouse 3 */
.mouse_3
  position absolute
  bottom 10px
  right 10px
  width 20px
  height 50px
  border 1px solid #697cb4
  border-radius: 50px
  &::after
    content ''
    position absolute
    background-color #d4dcff
    height 8px
    width 8px
    border-radius 50%
    left 50%
    transform translateX(-50%)
    animation move 2s linear infinite

@keyframes move
  0%
    transform translate(-50%, 0)
    opacity 0
  50%
    transform translate(-50%, 20px)
    opacity 1
  100%
    transform translate(-50%, 40px)
    opacity 0

</style>